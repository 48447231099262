<template>
  <cw-page
    icon="details"
    class="car-loan__sales-details"
  >
    <validation-observer
      ref="observer"
      autocomplete="off"
      tag="v-form"
    >
      <v-row>
        <v-col
          class="car-loan__sales-details__licenseNumber--wrapper"
          cols="12"
          md="6"
        >
          <cw-text-field
            id="licenseNumber"
            v-model="form.licenseNumber"
            :hint="licenseNumberHint"
            :label="licenseNumberLabel"
            rules="required|licenseNumber"
            type="text"
          />
        </v-col>
        <v-col
          class="car-loan__sales-details__carPrice--wrapper"
          cols="12"
          md="6"
        >
          <cw-text-field
            id="carPrice"
            v-model="form.carPrice"
            :hint="carPriceHint"
            :label="carPriceLabel"
            lang="en-150"
            rules="required|notNegativeNumber"
            suffix="€"
            type="number"
          />
        </v-col>
        <v-col>
          <p>
            <translate>
              Purpose of the loan
            </translate>
          </p>

          <v-btn-toggle
            id="carLoanPurpose"
            v-model="form.carLoanPurpose"
            color="primary"
            mandatory
          >
            <v-btn
              id="carLoanPurpose--purchase"
              value="purchase"
              @click="$eventLogger.clickEvent($event)"
            >
              <translate>
                Purchasing
              </translate>
            </v-btn>

            <v-btn
              id="carLoanPurpose--deposit"
              value="deposit"
              @click="$eventLogger.clickEvent($event)"
            >
              <translate>
                Down payment
              </translate>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </validation-observer>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :disabled="$wait.waiting('SUBMIT_FORM')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          validateAndSubmit();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Continue
        </translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions } from 'vuex';
import { goToError } from '@shared/mixins';

export default {
  name: 'CwCarLoanApplicationSalesDetails',

  components: {
    'cw-text-field': () => import('@shared/components/inputs/CwTextField'),
    'validation-observer': ValidationObserver,
  },

  mixins: [goToError],

  data: () => ({
    form: {
      licenseNumber: '',
      carPrice: null,
      carLoanPurpose: null,
    },
  }),

  computed: {
    /**
     * Gettext translations
     */
    licenseNumberHint: vm => vm.$gettext('Registration number, esim. ABC-123'),
    licenseNumberLabel: vm => vm.$gettext('Registration number'),
    carPriceHint: vm => vm.$gettext('Car purchase price in euros'),
    carPriceLabel: vm => vm.$gettext('The purchase price'),
  },

  methods: {
    ...mapActions({
      submitForm: 'application/submit',
    }),

    /**
     * Prepare form data for submit
     */
    async prepareFormData() {
      // build params based on the form values
      const params = { ...this.form };

      // remove unfilled fields from params
      Object.keys(params).forEach((key) => {
        if (params[key] === '' || params[key] === null) {
          delete params[key];
        }
      });
      return params;
    },

    async validateAndSubmit() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        this.goToError();

        return;
      }

      await this.submit();
    },

    async submit() {
      this.$wait.start('SUBMIT_FORM');

      const formParams = await this.prepareFormData();

      const routineParams = {
        uri: 'executeRoutine',
        routine: 'Submit Sales Details',
      };

      try {
        await this.submitForm({
          formParams,
          routineParams,
        });
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>
